import { IPaginatedResponse } from 'common/types/api.types'
import { http } from 'services/http'
import useSWRImmutable from 'swr/immutable'
import useSWRMutation, { SWRMutationConfiguration } from 'swr/mutation'

export type SWRConfig<T> = {
  revalidateOnFocus?: boolean
  revalidateOnReconnect?: boolean
  revalidateOnMount?: boolean
  refreshInterval?: number
  dedupingInterval?: number
  onError?: (error: any) => void
  onSuccess?: (data: T) => void
}

export const getResource = async <T>(path: string): Promise<T> => {
  const { data } = await http.get(path)

  return data as T
}

export const postResource = async <T, K>(path: string, { arg }: { arg: T }): Promise<K> => {
  const { data } = await http.post(path, arg)
  return data as K
}

export const patchResource = async <T, K>(path: string, { arg }: { arg: T }): Promise<K> => {
  const { data } = await http.patch(path, arg)
  return data as K
}

export const patchMultipartFormDataResource = async <T, K>(path: string, { arg }: { arg: T }): Promise<K> => {
  const { data } = await http.patch(path, arg, { headers: { 'Content-Type': 'multipart/form-data' } })
  return data as K
}

export const putResource = async <T, K>(path: string, { arg }: { arg: T }): Promise<K> => {
  const { data } = await http.put(path, arg)
  return data as K
}

export const deleteResource = async <K>(path: string): Promise<K> => {
  const { data } = await http.delete(path)
  return data as K
}

export const deleteWithBodyResource = async <T, K>(path: string, { arg }: { arg: T }): Promise<K> => {
  const { data } = await http.delete(path, { data: arg })
  return data as K
}

export const useGet = <T>(key: string | null, config: SWRConfig<T> = {}) => {
  const { data, isLoading, error, mutate } = useSWRImmutable(key, getResource<T>, config)

  return { data, isLoading, error, mutate }
}

export const useImmutableGet = <T>(key: string | null, config: SWRConfig<T> = {}) => {
  const { data, isLoading, error, mutate } = useSWRImmutable(
    key || !key?.includes('undefined') ? key : null,
    getResource<T>,
    config,
  )

  return { data, isLoading, error, mutate }
}

export const useGetPaginated = <T>(key: string | null, config: SWRConfig<IPaginatedResponse<T>> = {}) => {
  const { data, isLoading, error } = useSWRImmutable(key, getResource<IPaginatedResponse<T>>, { ...config })

  return {
    data: data?.data,
    metaData: data?.meta,
    error,
    isLoading,
  }
}

export const usePost = <T, K>(key: string, config: SWRMutationConfiguration<K, any, T, string, any> = {}) => {
  const { trigger, isMutating } = useSWRMutation(key, postResource<T, K>, {
    ...config,
  })

  return { trigger, isMutating }
}

export const usePut = <T, K>(key: string, config: SWRMutationConfiguration<K, any, T, string, any> = {}) => {
  const { trigger, isMutating } = useSWRMutation(key, putResource<T, K>, {
    ...config,
  })

  return { trigger, isMutating }
}

export const usePutWithPath = <T, K>(
  key: string,
  config: SWRMutationConfiguration<K, any, { path: string; body: T }, string, any> = {},
) => {
  const putResource = async ({ path, body }: { path: string; body: T }) => {
    const { data } = await http.put(path, body)
    return data as K
  }

  const { trigger, isMutating } = useSWRMutation(key, (_, body) => putResource(body.arg), {
    ...config,
  })

  return { trigger, isMutating }
}

export const usePatch = <T, K>(key: string, config: SWRMutationConfiguration<K, any, T, string, any> = {}) => {
  const { trigger, isMutating, error } = useSWRMutation(key, patchResource<T, K>, {
    ...config,
  })

  return { trigger, isMutating, error }
}

export const usePatchMultipart = <T, K>(key: string, config: SWRMutationConfiguration<K, any, T, string, any> = {}) => {
  const { trigger, isMutating } = useSWRMutation(key, patchMultipartFormDataResource<T, K>, {
    ...config,
  })

  return { trigger, isMutating }
}

export const useDelete = <T, K>(key: string, config: SWRMutationConfiguration<K, any, T, string, any> = {}) => {
  const { trigger, isMutating } = useSWRMutation(key, deleteResource<K>, {
    ...config,
  })

  return { trigger, isMutating }
}
export const useDeleteWithBody = <T, K>(key: string, config: SWRMutationConfiguration<K, any, T, string, any> = {}) => {
  const { trigger, isMutating } = useSWRMutation(key, deleteWithBodyResource<T, K>, {
    ...config,
  })

  return { trigger, isMutating }
}
